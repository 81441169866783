<template>
  <div class="article-display">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/overview' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>文章管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-search">
      <div class="left">
        <el-input placeholder="请输入文章标题" v-model="query.title" clearable @clear="getListData"></el-input>
        <el-button type="primary" @click="getListData">搜索</el-button>
      </div>
      <div class="right">
        <el-button type="primary" @click="add">添加文章</el-button>
      </div>
    </div>
    <div class="page-content">
      <el-row>
        <el-table :data="dataList" border style="width: 100%">
          <el-table-column prop="title" label="文章标题"></el-table-column>
          <el-table-column label="发布医院及病区">
            <template slot-scope="scope">
              <span v-if="scope.row.hospital_id !== 0">【{{ scope.row.hospital_title }}】· {{ scope.row.area_title }}</span>
              <span v-else>无</span>
            </template>
          </el-table-column>
          <el-table-column prop="nurse_name" label="发布护士" width="180"></el-table-column>
          <el-table-column prop="status" label="文章状态" width="180">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status === 1" type="warning">待审核</el-tag>
              <el-tag v-else-if="scope.row.status === 2" type="success">正常</el-tag>
              <el-tag v-else type="info">拒绝</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="admin" label="发布身份" width="180">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.is_admin === 1">护士发布</el-tag>
              <el-tag v-if="scope.row.is_admin === 2" type="warning">管理员发布</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button type="primary" icon="el-icon-edit" @click="editItem(scope.row.id)" size="small"></el-button>
              <el-button type="danger" icon="el-icon-delete" @click="deleteItem(scope.row.id)" size="small"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="table-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current" :page-sizes="[30, 100, 200, 400]" :page-size="query.size" layout="total, sizes, prev, pager, next, jumper" :total="query.total"></el-pagination>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleDisplay',
  data () {
    return {
      query: {
        title: '',
        size: 30,
        current: 1,
        total: 0
      },
      dataList: []
    }
  },
  created () {
    this.getListData()
  },
  methods: {
    async getListData () {
      const { data: res } = await this.$http.get('/article-list', { params: this.query })
      if (res.meta.status === 200) {
        this.dataList = res.data.list
        this.query.current = res.data.current
        this.query.total = res.data.total
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    handleSizeChange (val) {
      this.query.size = val
    },
    handleCurrentChange (val) {
      this.query.current = val
    },
    add () {
      this.$router.push('/dashboard/article-add')
    },
    editItem (id) {
      this.$router.push('/dashboard/article-edit/' + id)
    },
    async deleteItem (id) {
      const { data: res } = await this.$http.delete('/article/' + id)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        await this.getListData()
      } else {
        this.$message.error(res.meta.msg)
      }
    }
  }
}
</script>

<style scoped>
.table-pagination{
  padding: 20px 0;
  text-align: center;
}
.page-search{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}
.page-search .left{
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-search .left .el-button{
  margin-left: 14px;
}
</style>
